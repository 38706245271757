<template>
  <v-expansion-panel class="" value="0">
    <v-expansion-panel-header color="accent">
      <div class="float-left subtitle-1">
        <v-icon color="primary" class="iconFix mr-3">mdi-information</v-icon>
        {{ $vuetify.lang.t("$vuetify.company.info") }}
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="caption">
      <v-row>
        <v-col class="col-6">{{
          $vuetify.lang.t("$vuetify.status.account_state")
        }}</v-col>
        <v-col class="col-6" :class="this.info.status">{{ status }}</v-col>
      </v-row>
      <v-row>
        <v-col class="col-6">{{
          $vuetify.lang.t("$vuetify.company.reg_date")
        }}</v-col>
        <v-col class="col-6">{{ this.info.reg_date }}</v-col>
      </v-row>
      <v-row>
        <v-col class="col-6">{{
          $vuetify.lang.t("$vuetify.company.payed_to")
        }}</v-col>
        <v-col class="col-6">{{ this.info.payed_to }}</v-col>
      </v-row>

      <v-btn v-if="!limited && (this.info.status == 'active' || this.info.status == 'აქტიური')" small @click="$parent.$parent.confirmStatusUpdatePopup='excluded'" color="error" class="mt-2 mr-2 mb-2">
        {{ $vuetify.lang.t("$vuetify.action.exclude_company") }}
      </v-btn>

      <v-btn v-if="!limited && (this.info.status == 'excluded' || this.info.status == 'გარიცხული')" small @click="$parent.$parent.confirmStatusUpdatePopup='active'" color="success" class="mt-2 mr-2 mb-2">
        {{ $vuetify.lang.t("$vuetify.action.activate_company") }}
      </v-btn>

      <v-btn v-if="!limited && (this.info.status=='review' || this.info.status=='განსახილველი')" small @click="$parent.$parent.confirmStatusUpdatePopup='active'" color="success" class="mt-2 mr-2 mb-2">
        {{ $vuetify.lang.t("$vuetify.action.activate_company") }}
      </v-btn>

      <v-btn v-if="!limited && (this.info.status=='review' || this.info.status=='განსახილველი')" small @click="$parent.$parent.confirmStatusUpdatePopup='deleted'" color="error" class="mt-2 mr-2 mb-2">
        {{ $vuetify.lang.t("$vuetify.action.reject_company") }}
      </v-btn>

    </v-expansion-panel-content>
  </v-expansion-panel>

</template>

<script>
export default {
  data: function() {
    return {
      info: this.stats,
      confirmStatusDialog: false
    };
  },
  computed: {
    status() {
      return this.$i18n.messages[this.$i18n.locale]["$vuetify"]["status"][
        this.info.status
      ];
    },
  },
  watch: {
    stats: function(newVal) {
      this.info = newVal;
      this.info.name = this.companyName[this.$i18n.locale];
    },
  },
  props: ["stats", "companyID", "companyName", "limited"],
};
</script>
<style scoped>
.active {
  color: #00b74f;
}
.excluded {
  color: #f26334;
}
</style>
