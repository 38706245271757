<template>
  <v-expansion-panel class="mt-3" value="1">
    <v-expansion-panel-header color="accent">
      <div class="float-left subtitle-1">
        <v-icon color="primary" class="mx-auto iconFix mr-3">mdi-account</v-icon>
        {{$vuetify.lang.t('$vuetify.company.contacts')}}
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="caption">
      <v-expansion-panels class="mt-5"  :max-height="limitHeight" style="overflow:hidden; overflow-y: auto;">
        <v-expansion-panel
          v-for="(person, i) in cont"
          :key="i"
          hide-actions
          :id="'person_'+person.id"
        >
          <v-expansion-panel-header>
            <v-row align="center" class="spacer">
              <v-col cols="12">
                <div class="mb-2" v-html="person.name[$i18n.locale]"></div>
                <div class="grey--text">&nbsp;{{ person.position ? person.position[$i18n.locale] : null}}</div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-divider></v-divider>
            <v-card-text class="pl-0 pr-0">
              <v-row>
                <!-- about contact! -->
                <v-col cols="12">
                  <v-icon size="12px" class="mr-1">mdi-phone</v-icon>
                  <span>{{person.phone}}</span>
                  <br />
                  <v-icon size="12px" class="mr-1">mdi-email</v-icon><span>{{person.email}}</span>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions  v-if="!readOnly">
              <v-btn v-if="!person.export" small @click="$emit('gepir',person)">
                <v-icon left>mdi-file-export</v-icon>
                {{$vuetify.lang.t('$vuetify.action.export_gepir')}}
              </v-btn>
              <v-btn small @click="editContactWindow(person,i)">
                <v-icon left>mdi-pencil</v-icon>
              </v-btn>
              <v-btn small @click="personEdit = person; dialogDelete=true">
                <v-icon left>mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
            <RemoveContact
              v-if="!readOnly"
              :person_id="personEdit.id"
              :popupRemovePerson="dialogDelete"
              v-on:closeRemovePersonPopup="dialogDelete=false"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <AddContact
        :orgID="companyID"
        :popupAddPerson="dialogAdd"
        v-on:closeAddPersonPopup="dialogAdd=false"
        v-on:addContact="addContact"
      />
      <v-btn small @click="dialogAdd=true" v-if="companyID" class="mt-5">
        <v-icon left>mdi-plus</v-icon>
        {{$vuetify.lang.t('$vuetify.action.add_contact')}}
      </v-btn>
    </v-expansion-panel-content>
    <EditContact
      v-if="!readOnly"
      :item_id="person_id"
      :personInfo="personEdit"
      :popupEditPerson="dialogEdit"
      v-on:closeEditPersonPopup="dialogEdit=false"
    />
  </v-expansion-panel>
</template>

<script>
import AddContact from "@/components/shared/popup/AddContact";
import EditContact from "@/components/shared/popup/EditContact";
import RemoveContact from "@/components/shared/popup/RemoveContact";
export default {
  components: {
    AddContact,
    EditContact,
    RemoveContact
  },
  props: ["contacts", "companyID", "readOnly", "maxHeight"],
  data: function() {
    return {
      dialogEdit: false,
      dialogDelete: false,
      dialogAdd: false,
      personEdit: false,
      cont: this.contacts,
      person_id: false
    };
  },
  watch: {
    contacts: function(newVal) {
      this.cont = newVal;
      this.personEdit = false
    }
  },
  methods: {
    limitHeight() {
      return this.maxHeight+'px' ?? '350px'
    },
    addContact(v) {
      this.contacts.push(v);
    },
    editContactWindow(person,i) {
      this.personEdit = person
      this.person_id = i
      this.dialogEdit = true
    },
    editContact(v) {
      console.log(v)
      this.contacts[v.item].name = v.person.name;
      this.contacts[v.item].position = v.person.position;
      this.contacts[v.item].phone = v.person.phone;
      this.contacts[v.item].fax = v.person.fax;
      this.contacts[v.item].email = v.person.email;
    }
  }
};
</script>