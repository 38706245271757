<template>
  <v-expansion-panel class="mt-3" value="0">
    <v-expansion-panel-header color="accent">
      <div class="float-left subtitle-1">
        <v-icon color="primary" class="iconFix mr-3">mdi-settings</v-icon>
        {{ $vuetify.lang.t("$vuetify.manager.site_settings") }}
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="caption pt-5">
      {{ $vuetify.lang.t("$vuetify.action.change_password") }}
      <v-text-field
        type="password"
        v-model="oldPassword"
        :label="$vuetify.lang.t('$vuetify.manager.password')"
        @keyup="validatePasswords"
        :error="oldPassword_error ? true : false"
        :error-messages="oldPassword_error"
      ></v-text-field>
      <v-text-field
        type="password"
        v-model="newPassword"
        :label="$vuetify.lang.t('$vuetify.manager.new_pass')"
        @keyup="validatePasswords"
        :error="newPassword_error ? true : false"
        :error-messages="newPassword_error"
      ></v-text-field>
      <v-text-field
        type="password"
        v-model="reNewPassword"
        :label="$vuetify.lang.t('$vuetify.manager.password_confirm')"
        @keyup="validatePasswords"
        :error="reNewPassword_error ? true : false"
        :error-messages="reNewPassword_error"
      ></v-text-field>

      <v-btn
        small
        @click="updatePassword"
        class="mt-5"
        :disabled="hasPassswordError"
      >
        <v-icon left>mdi-content-save</v-icon>
        {{ $vuetify.lang.t("$vuetify.action.save") }}
      </v-btn>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Api from "@/services/api";
export default {
  data: function () {
    return {
      oldPassword: "",
      oldPassword_error: null,
      newPassword: "",
      newPassword_error: null,
      reNewPassword: "",
      reNewPassword_error: null,
      hasPassswordError: true,
    };
  },
  methods: {
    validatePasswords() {
      if (this.oldPassword.length > 0) {
        this.oldPassword_error =
          this.oldPassword.length < 3
            ? this.$vuetify.lang.t("$vuetify.errors.invalid_password")
            : null;
      }

      if (this.newPassword.length > 0) {
        this.newPassword_error =
          this.newPassword.length < 3
            ? this.$vuetify.lang.t("$vuetify.errors.weak_password")
            : null;
      }

      if (this.reNewPassword.length > 0) {
        this.reNewPassword_error =
          this.reNewPassword !== this.newPassword
            ? this.$vuetify.lang.t("$vuetify.errors.dont_match")
            : null;
      }

      if (
        !this.oldPassword_error &&
        !this.newPassword_error &&
        !this.reNewPassword_error &&
        this.oldPassword.length !== 0 &&
        this.newPassword.length !== 0 &&
        this.reNewPassword.length !== 0
      ) {
        this.hasPassswordError = false;
      }
    },
    updatePassword() {
      const data = {
        old_password: this.oldPassword,
        new_password: this.newPassword,
      };

      Api.user("password/change", data).then(() => {
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.errors.passsword_update_success"));
      }).catch((e) => {
        const errorCode = e.response.data.error;
        if(errorCode === 'invalid_old') {
          this.oldPassword_error = this.$vuetify.lang.t("$vuetify.errors.invalid_password")
          this.hasPassswordError = true;
        }
      });
    },
  },
};
</script>
<style>
.v-input__control .v-messages__wrapper {
  padding-top: 1px;
  padding-bottom: 5px;
}
</style>
<style scoped>
@media (max-width: 1510px) {
  .btns-layer div {
    min-width: 100% !important;
  }
}
</style>
