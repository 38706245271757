<template>
  <v-dialog v-model="dialogState" persistent width="95vw" max-width="600px">
    <v-card>
      <v-toolbar color="accent">
        <v-toolbar-title style="width:100%">
          <v-container fluid>
            <v-row>
              <v-col class="col-xs-10 col-sm-10 col-md-10">
                <v-icon class="iconFix mr-2" v-if="messageToRead.type == 0"
                  >mdi-message</v-icon
                >
                <span v-if="messageToRead.type == 0">{{
                  $vuetify.lang.t("$vuetify.status.private_message")
                }}</span>

                <v-icon
                  class="iconFix mt-n2 mr-2"
                  v-if="messageToRead.type == 1"
                  >mdi-alert-box</v-icon
                >
                <span v-if="messageToRead.type == 1">{{
                  $vuetify.lang.t("$vuetify.status.system_message")
                }}</span>
              </v-col>
              <v-col
                class="col-xs-2 col-sm-2 col-md-2"
                style="text-align:right"
              >
                <v-icon
                  @click="
                    dialogState = false;
                    $emit('closePopup');
                  "
                  id="closePopupBtn"
                  >mdi-close</v-icon
                >
              </v-col>
            </v-row>
          </v-container>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-title class="headline"></v-card-title>
      <v-card-text>
        <h4>{{ messageToRead.title[loc] }}</h4>
        <h6 class="mb-5">{{ messageToRead.date }}</h6>
        <div v-html="messageToRead.body[loc]"></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: function() {
    return {
      dialogState: false,
      messageToRead: {
        type: false,
        title: false,
        body: false,
        date: false,
        seen: false,
      },
    };
  },
  computed: {
    loc() {
      return this.$i18n.locale;
    },
  },
  watch: {
    popupReadMessageShow: function(newVal) {
      this.dialogState = newVal;
    },
    msgData: function(newVal) {
      this.messageToRead = newVal;

      // if (newVal &&  this.messageToRead.seen == 0 && this.seen) {
      //   Api.message("seen", this.messageToRead.id).then(() => {});
      // }
    },
  },
  props: ["msgData", "popupReadMessageShow", "seen"],
};
</script>
