<template>
  <v-dialog v-model="dialogState" persistent width="95vw" max-width="1200px" :retain-focus="false">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left>mdi-pencil</v-icon>
          {{$vuetify.lang.t('$vuetify.action.edit_contact')}}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-title class="headline"></v-card-title>
      <v-card-text>
        <v-form ref="contactEditForm">
          <v-row align="start">
            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.person_name_ka')+' *'"
                v-model="personData.name.ka"
                :error="validator.name_ka.error_show"
                :error-messages="validator.name_ka.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.person_name_en')+' *'"
                v-model="personData.name.en"
                :error="validator.name_en.error_show"
                :error-messages="validator.name_en.error_msg"
                @keyup="validateForm"
                v-on:keypress="latinOnly($event)"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.position_ka')"
                v-model="personData.position.ka"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.position_en')"
                v-model="personData.position.en"
                v-on:keypress="latinOnly($event)"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.email')+' *'"
                v-model="personData.email"
                :error="validator.email.error_show"
                :error-messages="validator.email.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.phone')"
                v-model="personData.phone"
                :error="validator.phone.error_show"
                :error-messages="validator.phone.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="closePopup">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{$vuetify.lang.t('$vuetify.action.cancel')}}
        </v-btn>

        <v-btn @click="editContact" :disabled="validator.hasError">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{$vuetify.lang.t('$vuetify.action.update')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
import ContactHelper from "@/helpers/contact";
export default {
  data: function() {
    return {
      dialogState: false,
      personDataOriginal: false,
      personData: ContactHelper.newItemModel(),
      validator: ContactHelper.validatorModel()
    };
  },
  watch: {
    popupEditPerson: function(newVal) {
      this.dialogState = newVal;
      if(newVal) {
        this.personData = Object.assign({}, this.personInfo);
        this.personDataOriginal = Object.assign({}, this.personInfo);
        this.validateForm()
      }
    },
  },
  methods: {
    validateForm() {
      this.validator = ContactHelper.validateForm(
        this.personData.name,
        this.personData.email,
        this.personData.phone
      );
    },
    closePopup() {
      this.dialogState = false;
      this.$emit("closeEditPersonPopup");
      this.validator = ContactHelper.validatorModel();
      this.personData = Object.assign({}, this.personDataOriginal)
    },
    editContact() {
      const d = {
        person: Object.assign({}, this.personData),
        item: this.item_id
      };

      Api.contact("update", this.personData).then(() => {
        console.log(this.$parent.$parent.editContact(d))
        console.log(d)

        this.personDataOriginal = Object.assign({}, this.personData);

        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.update_success"));
      });
      this.$emit("closeEditPersonPopup");
    }
  },
  props: ["item_id", "personInfo", "popupEditPerson"]
};
</script>