import vm from '@/main.js'
import * as HelperModel from '@/models/contact.js'

class ContactHelpers {

    validatorModel() {
        return JSON.parse(JSON.stringify(HelperModel.validator))
    }

    newItemModel() {
        return JSON.parse(JSON.stringify(HelperModel.newItem))
    }

    validateForm(name, email, phone) {
        var validator = this.validatorModel()
        if (name.ka === null || name.ka.length <= 0) {
            validator.name_ka.error_show = true;
            validator.name_ka.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.name_ka.error_show = false;
            validator.name_ka.error_msg = null;
            validator.hasError = false;
        }

        if (name.en === null || name.en.length <= 0) {
            validator.name_en.error_show = true;
            validator.name_en.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.name_en.error_show = false;
            validator.name_en.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/;
        if(email !== null && email != '') {
            if (email.length <= 0 || !re.test(email)) {
                validator.email.error_show = true;
                validator.email.error_msg = vm.$vuetify.lang.t(
                    "$vuetify.errors.required"
                );
                validator.hasError = true;
            } else {
                validator.email.error_show = false;
                validator.email.error_msg = null;
                validator.hasError = validator.hasError ? true : false;
            }
        }

        var regex = /^(\+?[0-9 ]+)$/;
        if(phone !== null && phone != '') {
            if (phone.length <= 0 || !regex.test(phone)) {
                validator.phone.error_show = true;
                validator.phone.error_msg = vm.$vuetify.lang.t(
                    "$vuetify.errors.required"
                )+`(+ nnn nnn nnn, nnnnnnnnn)`;
                validator.hasError = true;
            } else {
                validator.phone.error_show = false;
                validator.phone.error_msg = null;
                validator.hasError = validator.hasError ? true : false;
            }
        }

        return validator;
    }

}

const ContactHelper = new ContactHelpers
export default ContactHelper