<template>
  <v-dialog v-model="dialogState" persistent width="95vw" max-width="800px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left>mdi-plus</v-icon>
          {{$vuetify.lang.t('$vuetify.action.add_contact')}}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-row align="start">
            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.person_name_ka')+ ' *'"
                v-model="newItem.name.ka"
                :error="validator.name_ka.error_show"
                :error-messages="validator.name_ka.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.person_name_en')+ ' *'"
                v-model="newItem.name.en"
                :error="validator.name_en.error_show"
                :error-messages="validator.name_en.error_msg"
                @keyup="validateForm"
                v-on:keypress="latinOnly($event)"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.position_ka')"
                v-model="newItem.position.ka"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.position_en')"
                v-model="newItem.position.en"
                v-on:keypress="latinOnly($event)"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.email')"
                v-model="newItem.email"
                :error="validator.email.error_show"
                :error-messages="validator.email.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.company.phone')"
                v-model="newItem.phone"
                :error="validator.phone.error_show"
                :error-messages="validator.phone.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{$vuetify.lang.t('$vuetify.action.cancel')}}
        </v-btn>
        <v-btn @click="addContact" :disabled="validator.hasError">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{$vuetify.lang.t('$vuetify.action.save')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
import ContactHelper from "@/helpers/contact";
export default {
  data: function() {
    return {
      dialogState: false,
      newItem: ContactHelper.newItemModel(),
      validator: ContactHelper.validatorModel()
    };
  },
  methods: {
    validateForm() {
      this.validator = ContactHelper.validateForm(
        this.newItem.name,
        this.newItem.email,
        this.newItem.phone
      );
    },
    closePopup() {
      this.dialogState = false;
      this.$emit("closeAddPersonPopup");
      this.validator = ContactHelper.validatorModel();
      this.newItem = ContactHelper.newItemModel();
    },
    addContact() {
      const newContact = Object.assign({}, this.newItem);
      newContact.company_id = this.orgID;
      Api.contact("create", newContact).then(({ data }) => {
        this.$emit("addContact", data);
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.create_success"));
        this.closePopup();
      });
    }
  },
  watch: {
    popupAddPerson: function(newVal) {
      this.dialogState = newVal;
    }
  },
  props: ["orgID", "popupAddPerson"]
};
</script>