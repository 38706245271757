<template>
  <v-dialog v-model="dialogState" persistent width="95vw" max-width="320px" :retain-focus="false">
    <v-card>
      <v-card-text class="pt-3">
        <h3>{{$vuetify.lang.t('$vuetify.action.delete_confirm')}}</h3>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-1"
          text
          @click="dialogState = false, $emit('closeRemovePersonPopup')"
        >{{$vuetify.lang.t('$vuetify.action.cancel')}}</v-btn>
        <v-btn
          color="red darken-1"
          text
          @click="deleteContact(person_id)"
        >{{$vuetify.lang.t('$vuetify.action.confirm')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
export default {
  data: function() {
    return {
      dialogState: false
    };
  },
  methods: {
    deleteContact(personID) {
      Api.contact("delete", personID).then(() => {
        document.getElementById("person_" + personID).remove();
      });
      this.dialogState = false;
      this.$emit("closeRemovePersonPopup");
    }
  },
  watch: {
    popupRemovePerson: function(newVal) {
      this.dialogState = newVal;
    }
  },
  props: ["person_id", "popupRemovePerson"]
};
</script>