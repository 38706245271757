<template>
  <v-dialog v-model="dialogState" max-width="600px">
    <v-card>
      <v-toolbar color="accent">
        <v-toolbar-title style="width:100%">
          <v-container fluid>
            <v-row>
              <v-col cols="10">
                <v-icon class="iconFix mr-2">mdi-send</v-icon>
                <span>{{$vuetify.lang.t('$vuetify.action.send_private_message')}}</span>
              </v-col>
              <v-col cols="2" style="text-align:right">
                <v-icon
                  @click="dialogState = false; $emit('closeSendmessagePopupBtn')"
                  id="closeSendmessagePopupBtn"
                >mdi-close</v-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="messageToSend.title.ka"
                  :label="$vuetify.lang.t('$vuetify.message.subject_ka')"
                ></v-text-field>
                <v-textarea
                  v-model="messageToSend.body.ka"
                  :label="$vuetify.lang.t('$vuetify.message.message_ka')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="messageToSend.title.en"
                  :label="$vuetify.lang.t('$vuetify.message.subject_en')"
                ></v-text-field>
                <v-textarea
                  v-model="messageToSend.body.en"
                  :label="$vuetify.lang.t('$vuetify.message.message_en')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="sendMessage(orgID)" :disabled="messageToSend.title.ka == '' || messageToSend.title.ka == null || messageToSend.title.en == '' || messageToSend.title.en == null || messageToSend.body.ka == '' || messageToSend.body.ka == null || messageToSend.body.en == '' || messageToSend.body.en == null">
          <v-icon class="mr-3">mdi-send</v-icon>
          {{$vuetify.lang.t('$vuetify.action.send_message')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
export default {
  data: function() {
    return {
      dialogState: false,
      messageToSend: {
        orgID: false,
        title: {
          ka: null,
          en: null
        },
        body: {
          ka: null,
          en: null
        },
        type: 1
      }
    };
  },
  methods: {
    sendMessage(orgID) {
      this.messageToSend.company_id = orgID;

      Api.message("send", this.messageToSend).then((data) => {
        this.$emit('messageSent', data.data)
      });
    }
  },
  watch: {
    popupSendMessageShow: function(newVal) {
      this.dialogState = newVal;
    }
  },
  props: ["orgID", "popupSendMessageShow"]
};
</script>