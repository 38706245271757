export const validator = {
    hasError: true,
    name_ka: {
        error_show: false,
        error_msg: null
    },
    name_en: {
        error_show: false,
        error_msg: null
    },
    email: {
        error_show: false,
        error_msg: null
    },
    phone: {
        error_show: false,
        error_msg: null
    }
}

export const newItem = {
    name: {
        ka: null,
        en: null
    },
    position: {
        ka: null,
        en: null
    },
    email: null,
    phone: null,
    fax: null
}