<template>
  <v-expansion-panel class="mt-3" value="0">
    <v-expansion-panel-header color="accent">
      <div class="float-left subtitle-1">
        <v-icon color="primary" class="iconFix mr-3"
          >mdi-message-processing</v-icon
        >
        {{ $vuetify.lang.t("$vuetify.company.messages_archive") }}
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="caption">
      <v-list
        two-line
        style="max-height:350px; overflow:hidden; overflow-y: auto;"
      >
        <template v-for="item in messages">
          <v-subheader
            v-if="item.header"
            :key="item.header"
            v-text="item.header"
          ></v-subheader>

          <v-divider
            v-else-if="item.divider"
            :key="item.id"
            :inset="item.inset"
          ></v-divider>

          <v-list-item
            v-else
            :key="item.id"
            @click="readMessagePopup(item)"
            class="ml-n6 mr-n1"
          >
            <v-list-item-avatar class="mr-3 ml-3">
              <v-icon v-if="item.type == 0">mdi-message</v-icon>
              <v-icon v-if="item.type == 1">mdi-alert-box</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="item.title[loc]"></v-list-item-title>
              <v-list-item-subtitle v-html="item.date"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon v-if="item.seen == 1" color="grey lighten-1"
                >mdi-eye</v-icon
              >
              <v-icon v-else color="grey lighten-1">mdi-eye-off</v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <v-spacer class="mt-5 mb-5"></v-spacer>
      <v-btn v-if="!readOnly" small @click="sendMessagePopup()">
        <v-icon class="mr-3">mdi-send</v-icon>
        {{ $vuetify.lang.t("$vuetify.action.send_message") }}
      </v-btn>
    </v-expansion-panel-content>

    <ReadMessage
      :msgData="currentMessage"
      :seen="readOnly"
      :popupReadMessageShow="dialogReadMessage"
      v-on:closePopup="dialogReadMessage = false"
    />
    <SendMessage
      v-if="!readOnly"
      :orgID="orgID"
      :popupSendMessageShow="dialogSendMessage"
      v-on:messageSent="messageSent"
    />
  </v-expansion-panel>
</template>

<script>
import Api from "@/services/api";
import ReadMessage from "@/components/shared/popup/ReadMessage";
import SendMessage from "@/components/manager/popup/SendMessage";
export default {
  components: {
    ReadMessage,
    SendMessage,
  },
  props: ["messages", "orgID", "readOnly"],
  data: function() {
    return {
      dialogSendMessage: false,
      dialogReadMessage: false,
      currentMessage: {},
    };
  },
  computed: {
    loc() {
      return this.$i18n.locale;
    },
  },
  watch: {
    messages: function(newVal) {
      if(!newVal) {
        return
      }

      var m = this.readMessagePopup;
      if (this.readOnly) {
        newVal.forEach(function(e) {
          if (!e.seen) {
            return m(e);
          }
        });
      }
    },
  },
  methods: {
    messageSent(msg) {
      this.messages.push(msg);
      this.dialogSendMessage = false;
      this.$toast.ok(this.$vuetify.lang.t("$vuetify.message.sent_success"));
    },
    sendMessagePopup() {
      this.dialogSendMessage = true;
    },
    readMessagePopup(i) {
      this.dialogReadMessage = true;
      this.currentMessage = i;
      if (this.currentMessage) { // &&  this.currentMessage.seen == 0
        Api.message("seen", this.currentMessage.id).then(() => {});
        for(var mi=0; mi<this.messages.length; mi++) {
          if(this.messages[mi].id == this.currentMessage.id) {
            this.messages[mi].seen=1
          }
        }
      }
    },
  },
};
</script>
