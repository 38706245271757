<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col class="col-sm-12 col-xs-12 col-md-8">
          <!-- <template v-if="userData.companies.length > 0">
            <v-card>
              <v-toolbar color="accent" fixed>
                <v-toolbar-title>
                  <v-icon color="primary" class="mx-auto iconFix mr-3"
                    >mdi-check-bold</v-icon
                  >
                  {{ $vuetify.lang.t("$vuetify.company.selected") }}
                </v-toolbar-title>
              </v-toolbar>
            </v-card>
            <v-card-text column style="padding-top: 10px">
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    :items="companyNames"
                    v-model="userData.company_id"
                    :label="$vuetify.lang.t('$vuetify.company.selected')"
                    @change="companyChange"
                    item-text="name"
                    item-value="id"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="6" align="right">
                  <v-btn
                    small
                    class="ml-3 mt-2 btn btn-success py-5"
                    color="#008DBD"
                    @click="createNewCompany"
                    dark
                  >
                    <v-icon class="mr-1">mdi-content-save</v-icon>
                    {{ $vuetify.lang.t("$vuetify.company.new_registration") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </template> -->

          <v-card>
            <v-toolbar color="accent" fixed>
              <v-toolbar-title>
                <v-icon color="primary" class="mx-auto iconFix mr-3"
                  >mdi-briefcase</v-icon
                >
                {{ $vuetify.lang.t("$vuetify.company.info") }}
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text column style="padding-top: 10px" v-if="!readonly">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    id="title-ka"
                    :readonly="this.readonly"
                    v-model="company.name.ka"
                    :label="$vuetify.lang.t('$vuetify.company.name_ka')"
                    :rules="[rules.required, rules.real]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :readonly="this.readonly"
                    id="title-en"
                    v-model="company.name.en"
                    :label="$vuetify.lang.t('$vuetify.company.name_en')"
                    :rules="[rules.required, rules.real]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :readonly="this.readonly"
                    id="address_ka"
                    v-model="company.address.ka"
                    :label="$vuetify.lang.t('$vuetify.company.address_ka')"
                    :rules="[rules.required, rules.real]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :readonly="this.readonly"
                    id="address_en"
                    v-model="company.address.en"
                    :label="$vuetify.lang.t('$vuetify.company.address_en')"
                    :rules="[rules.required, rules.real]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :readonly="this.readonly"
                    v-model="selectedCityKA"
                    :items="citiesKA"
                    :label="$vuetify.lang.t('$vuetify.company.city_ka')"
                    item-text="name"
                    item-value="key"
                    @change="cityChange"
                    :rules="[rules.required]"
                  >
                    <template slot="selection" slot-scope="citiesKA">{{
                      citiesKA.item.name
                    }}</template>
                    <template slot="item" slot-scope="citiesKA">{{
                      citiesKA.item.name
                    }}</template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :readonly="this.readonly"
                    v-model="selectedCityEN"
                    :items="citiesEN"
                    :label="$vuetify.lang.t('$vuetify.company.city_en')"
                    @change="cityChange"
                    item-text="name"
                    item-value="key"
                    :rules="[rules.required]"
                  >
                    <template slot="selection" slot-scope="citiesEN">{{
                      citiesEN.item.name
                    }}</template>
                    <template slot="item" slot-scope="citiesEN">{{
                      citiesEN.item.name
                    }}</template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :readonly="this.readonly"
                    v-model="selectedMainfieldKA"
                    :items="mainfieldKA"
                    :menu-props="{ top: true, offsetY: true }"
                    :label="
                      $vuetify.lang.t(
                        '$vuetify.company.production_main_field_ka'
                      )
                    "
                    @change="mainFieldChange"
                    item-text="name"
                    item-value="key"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :readonly="this.readonly"
                    v-model="selectedMainfieldEN"
                    :items="mainfieldEN"
                    :menu-props="{ top: true, offsetY: true }"
                    :label="
                      $vuetify.lang.t(
                        '$vuetify.company.production_main_field_en'
                      )
                    "
                    @change="mainFieldChange"
                    item-text="name"
                    item-value="key"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :readonly="this.readonly"
                    id="manager_id"
                    v-model="company.manager_id"
                    :label="$vuetify.lang.t('$vuetify.company.manager_id')"
                    type="number"
                    :rules="[rules.required, rules.real_8]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    id="identity_code"
                    v-model="company.id_code"
                    :label="$vuetify.lang.t('$vuetify.company.identity_code')"
                    :readonly="this.readonly"
                    :rules="[rules.required, rules.real]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" v-if="this.readonly">
                  <v-text-field
                    id="prefix"
                    v-model="company.prefix"
                    :label="$vuetify.lang.t('$vuetify.company.prefix')"
                    :readonly="this.readonly"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" v-if="this.readonly">
                  <v-text-field
                    id="id"
                    v-model="company.reg_id"
                    :label="$vuetify.lang.t('$vuetify.company.reg_id')"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" v-if="this.readonly">
                  <v-text-field
                    id="gln"
                    v-model="company.gln"
                    :label="$vuetify.lang.t('$vuetify.company.gln')"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" v-if="this.readonly">
                  <v-text-field
                    id="reg_cost"
                    v-model="company.reg_cost"
                    :label="$vuetify.lang.t('$vuetify.company.reg_cost')"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :readonly="this.readonly"
                    id="postal_code"
                    v-model="company.postal_code"
                    :label="$vuetify.lang.t('$vuetify.company.postal_code')"
                    :rules="[rules.required, rules.real]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    id="phone"
                    v-model="company.phone"
                    :label="$vuetify.lang.t('$vuetify.company.phone')"
                    :readonly="this.readonly"
                    prefix="+"
                    type="number"
                    :rules="[rules.required, rules.real]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    id="email"
                    v-model="company.email"
                    :label="$vuetify.lang.t('$vuetify.company.email')"
                    :readonly="this.readonly"
                    :rules="[rules.required, rules.real]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    id="website"
                    v-model="company.website"
                    :label="$vuetify.lang.t('$vuetify.company.website')"
                    :readonly="this.readonly"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text column style="padding-top: 10px" v-else>
              <v-row>
                <v-col cols="12" md="6">
                  {{ $vuetify.lang.t("$vuetify.company.name_ka") }} <br />
                  <span class="font-weight-bold">{{ company.name.ka }}</span>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $vuetify.lang.t("$vuetify.company.name_en") }} <br />
                  <span class="font-weight-bold">{{ company.name.en }}</span>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $vuetify.lang.t("$vuetify.company.address_ka") }} <br />
                  <span class="font-weight-bold">{{ company.address.ka }}</span>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $vuetify.lang.t("$vuetify.company.address_en") }} <br />
                  <span class="font-weight-bold">{{ company.name.en }}</span>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $vuetify.lang.t("$vuetify.company.city_ka") }} <br />
                  <span class="font-weight-bold">{{
                    $i18n.messages.ka.$vuetify.city[selectedCityKA]
                  }}</span>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $vuetify.lang.t("$vuetify.company.city_en") }} <br />
                  <span class="font-weight-bold">{{
                    $i18n.messages.en.$vuetify.city[selectedCityEN]
                  }}</span>
                </v-col>
                <v-col cols="12" md="6">
                  {{
                    $vuetify.lang.t("$vuetify.company.production_main_field_ka")
                  }}
                  <br />
                  <span class="font-weight-bold">{{
                    $i18n.messages.ka.$vuetify.production_fields[
                      selectedMainfieldKA
                    ]
                  }}</span>
                </v-col>
                <v-col cols="12" md="6">
                  {{
                    $vuetify.lang.t("$vuetify.company.production_main_field_en")
                  }}
                  <br />
                  <span class="font-weight-bold">{{
                    $i18n.messages.en.$vuetify.production_fields[
                      selectedMainfieldEN
                    ]
                  }}</span>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $vuetify.lang.t("$vuetify.company.manager_id") }} <br />
                  <span class="font-weight-bold">{{ company.manager_id }}</span>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $vuetify.lang.t("$vuetify.company.identity_code") }} <br />
                  <span class="font-weight-bold">{{ company.id_code }}</span>
                </v-col>
                <v-col cols="12" md="6" v-if="this.readonly">
                  {{ $vuetify.lang.t("$vuetify.company.prefix") }} <br />
                  <span class="font-weight-bold">{{ company.prefix }}</span>
                </v-col>
                <v-col cols="12" md="6" v-if="this.readonly">
                  {{ $vuetify.lang.t("$vuetify.company.reg_id") }} <br />
                  <span class="font-weight-bold">{{ company.reg_id }}</span>
                </v-col>
                <v-col cols="12" md="6" v-if="this.readonly">
                  {{ $vuetify.lang.t("$vuetify.company.gln") }} <br />
                  <span class="font-weight-bold">{{ company.gln }}</span>
                </v-col>
                <v-col cols="12" md="6" v-if="this.readonly">
                  {{ $vuetify.lang.t("$vuetify.company.reg_cost") }} <br />
                  <span class="font-weight-bold">{{ company.reg_cost }}</span>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $vuetify.lang.t("$vuetify.company.postal_code") }} <br />
                  <span class="font-weight-bold">{{
                    company.postal_code
                  }}</span>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $vuetify.lang.t("$vuetify.company.phone") }} <br />
                  <span class="font-weight-bold">+{{ company.phone }}</span>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $vuetify.lang.t("$vuetify.company.email") }} <br />
                  <span class="font-weight-bold">{{ company.email }}</span>
                </v-col>
                <v-col cols="12" md="6">
                  {{ $vuetify.lang.t("$vuetify.company.website") }} <br />
                  <span class="font-weight-bold">{{ company.website }}</span>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions v-if="!this.readonly">
              <v-btn
                small
                class="ml-3 mb-2 btn btn-success"
                color="#008DBD"
                @click="createCompany"
                dark
              >
                <v-icon class="mr-1">mdi-content-save</v-icon>
                {{ $vuetify.lang.t("$vuetify.site.registration") }}
              </v-btn>
              <v-btn
                small
                class="ml-3 mb-2 btn btn-success"
                @click="cancelNewCompany"
              >
                <v-icon class="mr-1">mdi-content-save</v-icon>
                {{ $vuetify.lang.t("$vuetify.action.cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <!--right bar !-->
        <v-col class="col-xs-12 col-sm-12 col-md-4">
          <v-row class="mt-n3">
            <v-col class="col-xs-12 col-md-12">
              <v-expansion-panels v-model="panel">
                <CompanyStats
                  :companyID="userData.company_id"
                  :companyName="company.name"
                  :stats="this.company.statistics"
                  :limited="true"
                />
                <Contacts
                  :companyID="userData.company_id"
                  :contacts="company.contacts"
                  v-on:gepir="contact"
                  :readOnly="readonly"
                />
                <Messages
                  :messages="company.messages"
                  :companyID="userData.company_id"
                  :readOnly="true"
                />
                <Settings />
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import Api from "@/services/api";
import CompanyStats from "@/components/shared/expansion/CompanyStats";
import Contacts from "@/components/shared/expansion/Contacts";
import Messages from "@/components/shared/expansion/Messages";
import Settings from "@/components/customer/expansion/Settings";
export default {
  components: {
    CompanyStats,
    Contacts,
    Messages,
    Settings,
  },
  data: function () {
    return {
      userData: false,
      dialogImport: false,
      newCompany: false,
      company: {
        name: {
          ka: "",
          en: "",
        },
        address: {
          ka: "",
          en: "",
        },
        manager_id: "",
        id_code: "",
        postal_code: "",
        phone: "",
        email: "",
        website: "",
        statistics: {
          assigned_barcodes: 0,
          debt: 0,
          registered_products: 0,
          status: null,
          reg_date: null,
        },
      },
      companyOriginal: this.company,
      company_back: false,
      panel: 0,
      citiesKA: [],
      citiesEN: [],
      selectedCityKA: false,
      selectedCityEN: false,
      mainfieldEN: [],
      mainfieldKA: [],
      selectedMainfieldKA: false,
      selectedMainfieldEN: false,
      companyNames: [],
      currentMessage: {},
      readonly: true,
      rules: {
        required: (value) =>
          !!value || this.$vuetify.lang.t("$vuetify.errors.required_field"),
        real: (v) =>
          v.length >= 3 || this.$vuetify.lang.t("$vuetify.errors.real_info"),
        real_8: (v) =>
          v.length >= 8 || this.$vuetify.lang.t("$vuetify.errors.real_info"),
      },
    };
  },
  methods: {
    contact(v) {
      v.export = true;
      Api.contact("update", v).then(() => {
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.update_success"));
      });
    },
    loadLists() {
      let kaCitiesArray = this.$i18n.messages.ka.$vuetify.city;
      let enCitiesArray = this.$i18n.messages.en.$vuetify.city;

      for (var kaKey in kaCitiesArray) {
        this.citiesKA.push({ name: kaCitiesArray[kaKey], key: kaKey });
      }

      for (var enKey in enCitiesArray) {
        this.citiesEN.push({ name: enCitiesArray[enKey], key: enKey });
      }

      let kaFieldsArray = this.$i18n.messages.ka.$vuetify.production_fields;
      let enFieldsArray = this.$i18n.messages.en.$vuetify.production_fields;

      for (var kaFieldKey in kaFieldsArray) {
        this.mainfieldKA.push({
          name: kaFieldsArray[kaFieldKey],
          key: kaFieldKey,
        });
      }

      for (var enFieldKey in enFieldsArray) {
        this.mainfieldEN.push({
          name: enFieldsArray[enFieldKey],
          key: enFieldKey,
        });
      }
    },
    cityChange(i) {
      this.selectedCityKA = i;
      this.selectedCityEN = i;
      this.company.city = i;
    },
    mainFieldChange(i) {
      this.selectedMainfieldKA = i;
      this.selectedMainfieldEN = i;
      this.company.main_production_field = i;
    },
    createNewCompany() {
      this.company_back = JSON.parse(JSON.stringify(this.company));
      this.company = this.newCompany;
      this.userData.company_id = false;
      this.readonly = false;
    },
    cancelNewCompany() {
      this.company = this.company_back;
      this.userData.company_id = this.company.id;
      this.readonly = true;
    },
    createCompany() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.readonly = true;

      this.company.city = this.selectedCityEN;
      this.company.main_production_field = this.selectedMainfieldEN;

      Api.company("create", this.company).then((response) => {
        this.companyOriginal = Object.assign({}, response);
        this.company.statistics.status = response.data.status;
        if (localStorage.user) {
          let userData = JSON.parse(localStorage.user);
          const newCompanyId = response.data.id;

          userData.companies.push(newCompanyId);

          if (typeof userData.company_names === "undefined") {
            userData.company_names = {};
          }

          userData.company_names[String(newCompanyId)] = {
            en: this.company.name.en,
            ka: this.company.name.ka,
          };

          userData.company_id = newCompanyId;

          localStorage.setItem("user", JSON.stringify(userData));

          this.companyNames.push({
            name: this.company.name[this.$i18n.locale],
            id: newCompanyId,
          });

          this.userData.company_id = newCompanyId
        }
        location.reload();
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.update_success"));
      });
    },
    companyChange(i) {
      this.userData.company_id = i;
      this.company_id = i;
      localStorage.setItem("user", JSON.stringify(this.userData));
      this.getCompany();
      this.readonly = true;
    },
    getCompany() {
      Api.company("get", JSON.parse(localStorage.user).company_id).then(
        ({ data }) => {
          this.company = data;
          this.company.statistics.status = data.status;
          this.company.statistics.reg_date = data.reg_date;
          this.company.statistics.payed_to = data.payed_to;
          this.company.statistics.payment = {
            membership: 0,
            barcode: 0,
          };

          data.payments.forEach(
            (e) => (this.company.statistics.payment[e.payment_type] += e.amount)
          );

          this.companyOriginal = Object.assign({}, data);

          this.selectedCityKA = data.city;
          this.companyOriginalCityKA = Object.assign({}, data.city);

          this.selectedCityEN = data.city;
          this.companyOriginalCityEN = Object.assign({}, data.city);

          this.selectedFieldKA = data.production_fields;
          this.companyOriginalFieldsKA = Object.assign(
            {},
            data.production_fields
          );

          this.selectedFieldEN = data.production_fields;
          this.companyOriginalFieldsEN = Object.assign(
            {},
            data.production_fields
          );

          this.selectedMainfieldKA = data.main_production_field;
          this.companyOriginalMainFieldKA = Object.assign(
            {},
            data.main_production_field
          );

          this.selectedMainfieldEN = data.main_production_field;
          this.companyOriginalMainFieldEN = Object.assign(
            {},
            data.main_production_field
          );

          this.selectedTurnover = data.yearly_turnover;
          this.companyOriginalTurnover = Object.assign(
            {},
            data.yearly_turnover
          );
        }
      );
    },
  },
  created() {
    if (JSON.parse(localStorage.user).company_id) {
      this.getCompany();
    }
    this.loadLists();
    this.selectedCityKA = "tbilisi";
    this.selectedCityEN = "tbilisi";
    this.selectedMainfieldKA = "other";
    this.selectedMainfieldEN = "other";
    this.userData = JSON.parse(localStorage.user);
    this.newCompany = this.company;

    if (!this.userData.company_id) {
      this.readonly = false;
    }

    for (const key in this.userData.company_names) {
      this.companyNames.push({
        name: this.userData.company_names[key][this.$i18n.locale],
        id: key,
      });
    }

    if (this.companyNames) {
      this.userData.company_id =
        this.companyNames[this.companyNames.length - 1];
    }
  },
};
</script>
